<template>
  <div class="project_logo">
    <img src="./resources/gerbNN.svg" alt="" width="64" height="64" style="flex: none;">
    <div class="project_title">
      Министерство градостроительной деятельности и развития агломераций Нижегородской обл.
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>

</style>
